/**
 * This file contains the code for showing the quiz share options.
 * This will be called from quiz table when user clicks the quiz share button and when user
 * creates quiz and save it.
 */

import React from "react";
import {
  FacebookShareButton,
  FacebookShareCount,
  FacebookIcon,
  TwitterShareButton,
  TwitterShareCount,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinShareCount,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappShareCount,
  WhatsappIcon,
  EmailShareButton,
  EmailShareCount,
  EmailIcon,
} from "react-share";
import { NotificationManager } from "react-notifications";

var shareBtnSize = 48;

export class QuizShareForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quizid: null,
      copyText: "Copy",
      quiz_slug: null,
      selectedShareIcon: "link",
      userid: this.props.userid,
      fullShortLink: "",
      quizType: "",
      quizplayerUrl: "",
    };
    this.onChangeShareIcon = this.onChangeShareIcon.bind(this);
    this.renderShareIcons = this.renderShareIcons.bind(this);
    this.getQuizData = this.getQuizData.bind(this);
    this.publishshopifyquiz = this.publishshopifyquiz.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    // this.getQuizData();
    var quizid = this.props.history.location.state.quizid;//this.props.match.params.id;
    var quiz_slug = this.props.history.location.state.quizTitle;//this.props.location.search;
    var getquiztype = this.props.history.location.state.quizType;
    // get quiz title without slug
    var quizTitleWithoutSlug = this.props.history.location.state.quizTitleWithoutSlug;
    // remove html 
    var quizTitleWithoutHtml = quizTitleWithoutSlug.replace(/<[^>]*>?/gm, '');
    //change to slug
    var quizTitleSlugWithoutHtml = quizTitleWithoutHtml.toLowerCase().replace(/ /g, "-").replace(/[^\w-]+/g, "");
    
    
    this.setState({ quizid, quiz_slug:quizTitleSlugWithoutHtml, quizType: getquiztype });
    const validQuizTypes = ["qztype1", "qztype2", "qztype5", "qztype6","qztype7","qztype9"];
    if (validQuizTypes.includes(getquiztype)) {
      this.setState({ quizplayerUrl: "quizplayer2.html" });
    } else {
      this.setState({ quizplayerUrl: "quizplayer.html" });
    }
  }

  getQuizData() {
    var flag = false;
    var quizid = this.props.match.params.id;
    var queryurl = this.props.basepath + "quiz/quiz_shortlink";
    var postdata =
      "flag=" + flag + "&quizid=" + quizid + "&userid=" + this.state.userid;
    var self = this;
    fetch(queryurl, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: postdata,
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        if (response && response.status == 200 && response.data) {
          var short_link_generate =
            self.props.localpath + "play/" + response.data.short_link;
          self.setState({ fullShortLink: short_link_generate });
        } else {
          NotificationManager.error("Server error: ", response.message);
        }
      })
      .catch((err) => {
        NotificationManager.error("Server error: ", err.message);
      });
  }

  publishshopifyquiz() {
    var flag = false;
    var quizid = this.props.match.params.id;
    var queryurl = this.props.basepath + "quiz/publishshopifyquiz/" + quizid;
    //var postdata = "flag=" + flag + "&quizid=" + quizid + "&userid=" + this.state.userid;
    var self = this;
    fetch(queryurl, {
      method: "get",
      credentials: "include",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        if (response && response.status == 200 && response.data) {
          self.setState({ shopifyquizurl: response.data, errormsg: null });
        } else {
          self.setState({ shopifyquizurl: null, errormsg: response.message });
        }
      })
      .catch((err) => {
        NotificationManager.error("Server error: ", err.message);
      });
  }

  onChangeShareIcon(e) {
    var selectedShareIcon = e;
    var self = this;
    this.setState({ selectedShareIcon });
  }
  onClose() {
    this.setState({
      copyText: "Copy Done",
    });
    if (this.state.selectedShareIcon == "link") {
      var quizpath =
        this.props.localpath +
        this.state.quizplayerUrl +
        this.state.quiz_slug +
        "&quizid=" +
        this.state.quizid;
      // setTimeout(() => {
      //     this.props.history.push("/app/Quizzes");
      // }, 2000); //
      navigator.clipboard.writeText(quizpath);
    } else if (this.state.selectedShareIcon == "short-link") {
      // setTimeout(() => {
      //     this.props.history.push("/app/Quizzes");
      // }, 2000); //
      navigator.clipboard.writeText(this.state.fullShortLink);
    } else if (this.state.selectedShareIcon == "iframe") {
      var quizpath =
        this.props.localpath +
        this.state.quizplayerUrl +
        this.state.quiz_slug +
        "&quizid=" +
        this.state.quizid;
      var iframecode =
        "<iframe width='713' height='419' src='" +
        quizpath + "&isiframe=true" + 
        "' frameborder='0' allowfullscreen></iframe>";
      // setTimeout(() => {
      //     this.props.history.push("/app/Quizzes");
      // }, 2000); //
      navigator.clipboard.writeText(iframecode);
    } else if (this.state.selectedShareIcon == "widget") {
      var quizpath =
        this.props.localpath +
        this.state.quizplayerUrl +
        this.state.quiz_slug +
        "&quizid=" +
        this.state.quizid;
      var quizWidget = `<script type="text/javascript">
        
        (function()
        {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src="https://api.quizzes4leads.com/quiz-widget.js";
            var quizlink = "${quizpath +"&isiframe=true"}";
            var charsetmetatag = document.createElement('meta');
            charsetmetatag.setAttribute("charset", "UTF-8");
            document.getElementsByTagName("head")[0].appendChild(charsetmetatag);
            document.getElementsByTagName("head")[0].appendChild(script);
            if(script.readyState){
                script.onreadystatechange = function(){
                    if(script.readyState==='loaded' || script.readyState==='complete'){
                        script.onreadystatechange = null;
                        init(quizlink);
                    }
                }
            }
            else {  //Others
            script.onload = function() {
              init(quizlink);
            };
          } 
        })();
    
      </script>`;
      navigator.clipboard.writeText(quizWidget);
    } else {
      this.setState({
        removeCopyBtn: true,
      });
    }
  }

  renderShareIcons() {
    var shareUrl =
      this.props.localpath +
      this.state.quizplayerUrl +
      "?quizid=" +
      this.state.quizid;
    var title = "My Quiz";
    return (
      <div className="col-md-8 comment_column">
        <div className="row">
          <div col-md-12>
            <div className="comment_icon">
              <div className="">
                <FacebookShareButton url={shareUrl} quote={title}>
                  <FacebookIcon size={shareBtnSize} round />
                </FacebookShareButton>
              </div>
              <div className="">
                {" "}
                <TwitterShareButton url={shareUrl} quote={title}>
                  <TwitterIcon size={shareBtnSize} round />
                </TwitterShareButton>
              </div>
              <div className="">
                {" "}
                <LinkedinShareButton
                  url={shareUrl}
                  quote={title}
                  windowWidth={750}
                  windowHeight={600}
                >
                  <LinkedinIcon size={shareBtnSize} round />
                </LinkedinShareButton>
              </div>
              <div className="">
                {" "}
                <WhatsappShareButton
                  url={shareUrl}
                  quote={title}
                  seperator="::"
                >
                  <WhatsappIcon size={shareBtnSize} round />
                </WhatsappShareButton>
              </div>
              <div className="">
                {" "}
                <EmailShareButton
                  url={shareUrl}
                  subject={title}
                  body="email body"
                >
                  <EmailIcon size={shareBtnSize} round />
                </EmailShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    var content = null;
    var quizpath =
      this.props.localpath +
      this.state.quizplayerUrl + 
      "?quiztitle="+this.state.quiz_slug +
      "&quizid=" + this.state.quizid;
    var quizWidget = `<script type="text/javascript">
        
        (function()
        {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src="https://api.quizzes4leads.com/quiz-widget.js";
            var quizlink = "${quizpath+"&isiframe=true"}";
            var quizId = "${this.state.quizid}";
            var charsetmetatag = document.createElement('meta');
            charsetmetatag.setAttribute("charset", "UTF-8");
            document.getElementsByTagName("head")[0].appendChild(charsetmetatag);
            document.getElementsByTagName("head")[0].appendChild(script);
            if(script.readyState){
                script.onreadystatechange = function(){
                    if(script.readyState==='loaded' || script.readyState==='complete'){
                        script.onreadystatechange = null;
                        init(quizlink,quizId);
                    }
                }
            }
            else {  //Others
            script.onload = function() {
              init(quizlink,quizId);
            };
          } 
        })();
    
      </script>`;
    var iframecode =
      "<iframe width='713' height='419' src='" +
      quizpath +"&isiframe=true" +
      "' frameborder='0' allowfullscreen></iframe>";
    if (this.state.selectedShareIcon == "link") {
      content = quizpath;
    } else if (this.state.selectedShareIcon == "iframe") {
      content = iframecode;
    } else if (this.state.selectedShareIcon == "widget") {
      content = quizWidget;
    } else if (this.state.selectedShareIcon == "short-link") {
      this.getQuizData();
      content = this.state.fullShortLink;
    } else if (this.state.selectedShareIcon == "shopify") {
      content = (
        <>
          <br />
          <label>Publish this quiz to shopify app</label> <br />
          <br />
          <label>
            {this.state.shopifyquizurl != null ? (
              <>
                Your Quiz has been published on your shopify store. <br />
                Here is the link -{" "}
                <a href={`${this.state.shopifyquizurl}`} target="_blank">
                  {this.state.shopifyquizurl}
                </a>
              </>
            ) : (
              <>{this.state.errormsg}</>
            )}
          </label>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.publishshopifyquiz}
          >
            {" "}
            Publish to shopify
          </button>
        </>
      );
    } else {
      content = this.renderShareIcons();
    }

    return (
      <section id="quiz_block">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-centered margin_bottom">
              <div className="row">
                <div className="col-md-12">
                  <div className="quiz_block-title">
                    <h3>
                      <span>
                        <i className="fa fa-wrench" aria-hidden="true"></i>
                      </span>
                      Your Quiz Is Live
                    </h3>
                  </div>
                  <hr className="custome_link" />
                </div>
              </div>
              <div className="row">
                <ul id="new_quiz_tab" className="nav nav-tabs">
                  <li className="">
                    <a
                      id="myTab"
                      data-toggle="tab"
                      name="link"
                      onClick={() => this.onChangeShareIcon("link")}
                      href="#home"
                    >
                      {" "}
                      <div className="quize_install_wrapp">
                        <div className="quiz_install_img">
                          <img src="/../../assets/img/link.jpeg" alt="" />
                        </div>
                        <div className="quiz_install_text">
                          <h4>
                            <span>
                              <i className="fa fa-link" aria-hidden="true"></i>
                            </span>{" "}
                            Link
                          </h4>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      id="myTab"
                      data-toggle="tab"
                      name="link"
                      onClick={() => this.onChangeShareIcon("short-link")}
                      href="#menu1"
                    >
                      <div className="quize_install_wrapp">
                        <div className="quiz_install_img">
                          <img src="/../../assets/img/link.jpeg" alt="" />
                        </div>
                        <div className="quiz_install_text">
                          <h4>
                            <span>
                              <i className="fa fa-link" aria-hidden="true"></i>
                            </span>
                            Short Link
                          </h4>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      id="myTab"
                      data-toggle="tab"
                      name="link"
                      onClick={() => this.onChangeShareIcon("iframe")}
                      href="#menu2"
                    >
                      <div className="quize_install_wrapp">
                        <div className="quiz_install_img">
                          <img src="/../../assets/img/ifram.jpeg" alt="" />
                        </div>
                        <div className="quiz_install_text">
                          <h4>
                            <span>
                              <i
                                className="fa fa-firefox"
                                aria-hidden="true"
                              ></i>
                            </span>
                            Iframe
                          </h4>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      id="myTab"
                      data-toggle="tab"
                      name="link"
                      onClick={() => this.onChangeShareIcon("widget")}
                      href="#menu3"
                    >
                      <div className="quize_install_wrapp">
                        <div className="quiz_install_img">
                          <img src="/../../assets/img/widget.jpeg" alt="" />
                        </div>
                        <div className="quiz_install_text">
                          <h4>
                            <span>
                              <i
                                className="fa fa-random"
                                aria-hidden="true"
                              ></i>
                            </span>{" "}
                            Widget
                          </h4>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      id="myTab"
                      data-toggle="tab"
                      name="link"
                      onClick={() => this.onChangeShareIcon("social")}
                      href="#menu4"
                    >
                      <div className="quize_install_wrapp">
                        <div className="quiz_install_img">
                          <img src="/../../assets/img/social.jpeg" alt="" />
                        </div>
                        <div className="quiz_install_text">
                          <h4>
                            <span>
                              <span>
                                <i
                                  className="fa fa-commenting-o"
                                  aria-hidden="true"
                                ></i>
                              </span>{" "}
                              Social
                            </span>
                          </h4>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div id="tab_content_wrapp" className="tab-content">
                <div
                  id="home"
                  className={`tab-pane ${
                    this.state.selectedShareIcon == "link" ? "active" : "fade"
                  }`}
                >
                  <div className="new_quize_textarea">
                    <textarea value={content}></textarea>
                  </div>
                </div>
                <div
                  id="menu1"
                  className={`tab-pane ${
                    this.state.selectedShareIcon == "short-link"
                      ? "active"
                      : "fade"
                  }`}
                >
                  <div className="new_quize_textarea">
                    <textarea value={content}></textarea>
                  </div>
                </div>
                <div
                  id="menu2"
                  className={`tab-pane ${
                    this.state.selectedShareIcon == "iframe" ? "active" : "fade"
                  }`}
                >
                  <div className="new_quize_textarea">
                    <textarea value={content}></textarea>
                  </div>
                </div>
                <div
                  id="menu3"
                  name="widget"
                  className={`tab-pane ${
                    this.state.selectedShareIcon == "widget" ? "active" : "fade"
                  }`}
                >
                  <div className="new_quize_textarea">
                    <textarea value={content}></textarea>
                  </div>
                </div>
                <div
                  id="menu4"
                  name="social"
                  className={`tab-pane ${
                    this.state.selectedShareIcon == "social" ? "active" : "fade"
                  }`}
                >
                  <div className="new_quize_textarea">{content}</div>
                </div>
                <div className="copy_btn">
                  <button onClick={this.onClose}>
                    {this.state.copyText != "Copy Done" ? (
                      <i className="fa fa-clone" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-check" aria-hidden="true"></i>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
