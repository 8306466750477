import React, { Component } from "react";
import ScheduleRetakeBtn from "./ScheduleRetakeBtn";
import SocialSharing from "./SocialSharing";

class CategoryGradedPersonalityResult extends Component {
  render() {
    const {
      theme_colors,
      restartQuiz,
      is_enable_social_media,
      enable_social_sharing_text,
      title,
      welcome_message_color,
      selectFont,
    } = this.props;


    return (
      <>
        <div className="col-12">
          <div
            className="quiz_wrap__content text-center"
            style={{
              background: theme_colors ? theme_colors.header_content_bg : "",
              color: theme_colors.header_content_text
                ? theme_colors.header_content_text
                : "",
            }}
          >
            <div className="quiz_wrap__content___text ql-editor text-center">
              <div className="mb-3">
                <h2
                  className={selectFont ? selectFont : ""}
                  style={{
                    color: welcome_message_color ? welcome_message_color : "",
                  }}
                >
                  {this.props.results.quizscoremsg}
                </h2>
              </div>
              <p
                className={selectFont ? selectFont : ""}
                style={{
                  color: welcome_message_color ? welcome_message_color : "",
                }}
              >
                {this.props.results.personalitydetails.description}
              </p>
              {this.props.results.personalitydetails.quecategoryimage && (
                <img
                  className="img-fluid"
                  style={{ height: 400 }}
                  src={this.props.results.personalitydetails.quecategoryimage}
                  alt=""
                />
              )}
            </div>
            <ScheduleRetakeBtn
              restartQuiz={restartQuiz}
              theme_colors={theme_colors}
              welcome_message_color={welcome_message_color}
              selectFont={selectFont}
            />
            {is_enable_social_media && (
              <SocialSharing
                enable_social_sharing_text={enable_social_sharing_text}
                title={title}
                welcome_message_color={welcome_message_color}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
export default CategoryGradedPersonalityResult;
