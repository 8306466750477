/**
 This file contains global variables to be used in apps
 */

import React from 'react';
import {
  Route,
  Redirect,
} from "react-router-dom";

// this variable contains data for showing the sidebar
export var navItemsArr = [
  { pathname: '/', label: 'Dashboard', icon: 'tachometer', url: '/app/', type: 'appdashboard' },
  { pathname: '/about', label: 'Quizzes', icon: 'pencil-square-o', url: '/app/Quizzes', type: 'quizdashboard' },
  { templates: '/templates', label: 'Templates', icon: 'newspaper', url: '/app/Templates', type: 'templates' },
  { templates: 'feedback', label: 'Web Response', icon: 'surprise', url: '/app/FeedbackLists', type: 'feedback' },
  { templates: '/poll/lists', label: 'Poll', icon: 'poll', url: '/app/poll/lists', type: 'poll' },
  // { templates: '/wheel_spinner/lists', label: 'Wheel Spinner', icon: 'wheel-spinner', url: '/app/wheel_spinner/lists', type: 'wheel_spinner' },
  { pathname: '/button-demo', label: 'Plans', icon: 'bars', url: '/app/Plans', type: 'plandashboard' },
  { pathname: '/progress-bars', label: 'Invoices', icon: 'dollar', url: '/app/Invoices', type: 'invoicesdashboard' },
  { pathname: '/table-demo', label: 'Account', icon: 'user', url: '/app/Account', type: 'usersettingdashboard' },
  { pathname: '/table-demo1', label: 'Settings', icon: 'cog', url: '/app/Settings', type: 'settingdashboard' },
  { pathname: '/video-tutorial', label: 'Tutorials', icon: 'video', url: '/app/Tutorials', type: 'tutorials' }

];



// this array will contain benchmarking data
export var cardItemsArr = [
  { key: 'Quiz Count', title: 'Created', stats: '', icon: 'line-chart', iconsize: 3,'status':'show' },
  { key: 'Published Quiz Count', title: 'Quiz Published', stats: '', icon: 'check-square-o', iconsize: 3,'status':'hide' },
  { key: 'Draft Quiz Count', title: 'Quiz Drafted', stats: '', icon: 'edit', iconsize: 3,'status':'hide' },
  { key: 'Views', title: 'Viewed', stats: '', icon: 'eye', iconsize: 3,'status':'show' },
  { key: 'Total Played', title: 'Started', stats: '', icon: 'puzzle-piece', iconsize: 3,'status':'show' },
  { key: 'Completed', title: 'Completed', stats: '', icon: 'flag-checkered', iconsize: 3,'status':'show' },
  { key: 'Total Questions', title: 'Total Questions', stats: '', icon: 'question-circle', iconsize: 3,'status':'hide' },
  { key: 'Web Response Count', title: 'Web Response Created', stats: '', icon: 'line-chart', iconsize: 3,itemType:'web-response' },
  { key: 'Web Response Completed', title: 'Web Response Completed', stats: '', icon: 'flag-checkered', iconsize: 3,itemType:'web-response' },


];

// this array contain the icons to be shown in dashboard with data cards.
export var iconArr = ['line-chart', 'wechat', 'bullseye', 'rss', 'user', 'edit', 'list-ol', 'heart'];

// this array is used for displaying data table
export var tableItemsArr = {
  header: [[]],
  rowdata: [[],
  []]
};


// this array is used for declaring themes
export var themeArr = [
  { "id": 12, "name": "Transparent x Light ", "value": "theme12" },
  { "id": 13, "name": "Transparent x Dark ", "value": "theme13" },
  { "id": 1, "name": "Sweet Peachy", "value": "theme1" },
  { "id": 2, "name": "Melting Mauve", "value": "theme2" },
  { "id": 3, "name": "Teal green", "value": "theme3" },
  { "id": 4, "name": "Sunny Daze", "value": "theme4" },
  { "id": 5, "name": "Rose Pink", "value": "theme5" },
  { "id": 6, "name": "Soft Crimson", "value": "theme6" },
  { "id": 7, "name": "Pulpy Orange", "value": "theme7" },
  { "id": 8, "name": "Fiercy Red", "value": "theme8" },
  { "id": 9, "name": "Starry Blue", "value": "theme9" },
  { "id": 10, "name": "Grass Green", "value": "theme10" },
  { "id": 11, "name": "Blue x Gray", "value": "theme11" },
  { "id": 12, "name": "Custom Theme", "value": "custom_theme" },

  // ,
  // {"id":11, "name":"Theme11", "value":"theme11"},
  // {"id":12, "name":"Theme12", "value":"theme12"},
  // {"id":13, "name":"Theme13", "value":"theme13"},
  // {"id":14, "name":"Theme14", "value":"theme14"},
  // {"id":15, "name":"Theme15", "value":"theme15"},
  // {"id":16, "name":"Theme16", "value":"theme16"},
  // {"id":17, "name":"Theme17", "value":"theme17"},
  // {"id":18, "name":"Theme18", "value":"theme18"},
  // {"id":19, "name":"Theme19", "value":"theme19"},
  // {"id":20, "name":"Theme20", "value":"theme20"},
  // {"id":21, "name":"Theme21", "value":"theme21"}

];

export var quizTypeArr = [
  { "id": 1, "name": "Graded Quiz", "value": "qztype1", 'status': 'active', 'backgroundimg': 'https://res.cloudinary.com/quizzes4leads/image/upload/v1679549499/quiztypes/quiztype2_xnu8ux.png' },
  { "id": 2, "name": "Personality Quiz", "value": "qztype2", 'status': 'active', 'backgroundimg': 'https://res.cloudinary.com/quizzes4leads/image/upload/v1679549499/quiztypes/quiztype3_vhc7km.png' },
  { "id": 3, "name": "Feedback", "value": "qztype3", 'status': 'inactive' },
  { "id": 4, "name": "Poll", "value": "qztype4", 'status': 'inactive' },
  { "id": 5, "name": "Graded Quiz with Personality", "value": "qztype5", 'status': 'active', 'backgroundimg': 'https://res.cloudinary.com/quizzes4leads/image/upload/v1679549499/quiztypes/quiztype1_imosln.png' },
  { "id": 6, "name": "Weighted Graded Quiz", "value": "qztype6", 'status': 'active', 'backgroundimg': 'https://res.cloudinary.com/quizzes4leads/image/upload/v1678932042/quiztypes/Weighted_Graded_Quiz_rrbpy3.png' },
  { "id": 7, "name": "Multiple Personalities Quiz", "value": "qztype7", 'status': 'active', 'backgroundimg': 'https://res.cloudinary.com/quizzes4leads/image/upload/v1685331329/quiztypes/multiple_personalites_jq9sz2.png' },
  // { "id": 8, "name": "Category Quiz - Multiple Personality", "value": "qztype8", 'status': 'active', 'backgroundimg': 'https://res.cloudinary.com/quizzes4leads/image/upload/v1709088339/quiztypes/categoryquiz_qse532.png' },
  { "id": 9, "name": "Category Quiz - Graded Personality", "value": "qztype9", 'status': 'active', 'backgroundimg': 'https://res.cloudinary.com/quizzes4leads/image/upload/v1709088339/quiztypes/categoryquiz_qse532.png' }
  
];



// this variable is no longer used and can be deleted.
export var tabItemArr = [
  { "id": 1, "tabname": "Page Health Index", "tabcontent": "Test1" },
  { "id": 2, "tabname": "Post", "tabcontent": "Post content" },
  { "id": 3, "tabname": "Engagement", "tabcontent": "Engagement content" },
  { "id": 4, "tabname": "Fans", "tabcontent": "Fans content" },
  { "id": 5, "tabname": "Page Stats", "tabcontent": "Page Stats content" },
  { "id": 6, "tabname": "Predictive Planning", "tabcontent": "Predictive Planning content" }];

export var pageItemsArr = [{}, {}];


export const UserAuth = { isAuthenticated: false, authenticate(flag) { this.isAuthenticated = flag; }, signout() { this.isAuthenticated = false } };

export const adminusers = ['smb-5fee4f77a1523566bee2d89b802b25ff', 'smb-80306cdd77eac83a87dedfff7593b096'];

export function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        UserAuth.isAuthenticated ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
}
